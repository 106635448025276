<template>
  <div ref="highchart-container" style="height: 100%; width: 100%">
    <highcharts
      v-if="seriesValues.length && ready"
      ref="chart"
      :options="chartOptions"
      style="width: 100%"
    ></highcharts>
  </div>

</template>

<script>
import {Chart as highcharts} from "highcharts-vue";
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'VQBCellWidgetTemplate',
  mixins: [WidgetViewMixin],
  components: {highcharts},
  props: {
    result: {type: Array},
  },
  mounted() {
    this.ready = true; // Почему-то при ресайзе ref теряется
  },
  computed: {
    chartOptions() {
      return {
        title: {
          text: this.data.title
        },
        colors: this.data.colors.map(color => color.value),
        credits: {enabled: false},
        chart: {
          type: this.data.type || 'line',
          height: this.$refs["highchart-container"].clientHeight,
        },
        xAxis: {
          labels:{enabled: this.data.labelsX || false},
          type: 'category',
          title: {
            text: this.data.xAxisTitle || ''
          }
        },
        yAxis: {
          labels:{enabled: this.data.labelsY || false},
          title: {
            text: this.data.yAxisTitle || ''
          }
        },
        tooltip: {
          enabled: this.data.tooltips || false,
          format: '<span style="font-size: 0.8em">{key}</span><br/>' +
            '{#each points}' +
            '<span style="color:{color}">\u25CF</span> ' +
            '{series.name}: <b>{y}</b><br/>' +
            '{/each}',
          shared: (this.seriesValues.length > 1),
        },
        legend: {enabled: this.data.legend || false},
        series: this.seriesValues
      };
    },

    seriesValues(){
      if( !this.result || !this.result.length ){
        return [];
      }

      let data = [];
      this.result.forEach(resultRow => {
        if( !resultRow.result || resultRow.result.headers.length < 2 ) {
          return;
        }

        let seriesData = [];
        resultRow.result.data.forEach(row => {
          seriesData.push([
            row[resultRow.result.headers[0].column], // Column
            parseFloat(row[resultRow.result.headers[1].column]) // Value
          ])
        })
        data.push({
          name: resultRow.name,
          data: seriesData,
        });
      })

      return data;
    }
  },
  data(){
    return {
      ready: false,
    }
  },
  methods: {}
}
</script>